import "styles/pages/page-blog.scss";

import React from "react";
import { Link, graphql } from "gatsby";
import LazyLoad from "react-lazyload";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Blog = ({ data: { allNews, firstNews } }) => {
	return (
		<Layout headerColor="#0260DB">
			<Seo title="Wiadomości dla podróżujących" />
			<SubpageHeader
				image={require("assets/images/news-top-bg.jpg").default}
				borderColor="#0260DB"
			/>

			<section className="section">
				<div className="container">
					<h2 className="section-title">
						Wiadomości dla podróżujących
					</h2>
				</div>
			</section>

			<section className="blog">
				<div className="container">
					<div className="blog-tile blog-tile--main">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="blog-tile__inner blog-tile__inner--main">
									<h4>{firstNews.edges[0].node.title}</h4>
									<div
										dangerouslySetInnerHTML={{
											__html: firstNews.edges[0].node
												.excerpt,
										}}
									/>
									<Link
										to={`/wiadomosci-podroznicze/${firstNews.edges[0].node.slug}/`}
										className="button button--yellow"
									>
										Czytaj więcej
									</Link>
								</div>
							</div>
							<div className="col-md-6">
								<div
									className="blog-tile__thumb blog-tile__thumb--main"
									style={{
										backgroundImage: `url(${
											firstNews.edges[0].node
												.featuredImage &&
											firstNews.edges[0].node
												.featuredImage.node.sourceUrl
										})`,
									}}
								/>
								<div className="blog-tile__info blog-tile__info--news">
									<div>
										<div className="blog-tile__user"></div>
									</div>
									<div>
										<span>Najnowszy</span>
										<data>
											{firstNews.edges[0].node.date
												.split("T")[0]
												.split("-")
												.reverse()
												.join(".")}
										</data>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						{allNews?.edges?.slice(0).map((item, index) => (
							<div className="col-md-4" key={index}>
								<div className="blog-tile">
									<LazyLoad>
										<div
											className="blog-tile__thumb"
											style={{
												backgroundImage: `url(${
													item.node.featuredImage &&
													item.node.featuredImage.node
														.sourceUrl
												})`,
											}}
										/>
									</LazyLoad>
									<div className="blog-tile__info blog-tile__info--news">
										<div>
											<div className="blog-tile__user" />
										</div>
										<data>
											{item.node.date
												.split("T")[0]
												.split("-")
												.reverse()
												.join(".")}
										</data>
									</div>
									<div className="blog-tile__inner">
										<div>
											<h4>{item.node.title}</h4>
											<div
												dangerouslySetInnerHTML={{
													__html: item.node.excerpt,
												}}
											/>
										</div>
										<Link
											to={`/wiadomosci-podroznicze/${item.node.slug}/`}
											className="button button--yellow"
										>
											Czytaj więcej
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		firstNews: allWpNew(limit: 1, sort: { fields: date, order: DESC }) {
			edges {
				node {
					slug
					title
					content
					excerpt
					date
					featuredImage {
						node {
							sourceUrl
						}
					}
				}
			}
		}
		allNews: allWpNew(sort: { fields: date, order: DESC }, skip: 1) {
			edges {
				node {
					slug
					title
					content
					excerpt
					date
					featuredImage {
						node {
							sourceUrl
						}
					}
				}
			}
		}
	}
`;

export default Blog;
